<template>
  <div>
    <!--begin::content-header-->
      <!--empty-->
    <!--end::content-header-->

    <!--begin::signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-5">
        <h3 class="font-size-h1">
          {{ trans('auth.login.login') }}
        </h3>
        <p class="text-muted font-weight-semi-bold">
          {{ trans('auth.login.emailAndPassword') }}
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <b-form-group
          id="input-email-group"
          :label="capitalize(transChoice('global.email', 1))"
          label-for="login-email"
        >
          <b-form-input
            id="login-email"
            v-model="$v.email.$model"
            class="form-control form-control-solid h-auto py-5 px-6"
            name="login-email"
            :state="validateState('email')"
            :placeholder="trans('auth.login.yourEmail')"
            aria-describedby="login-email-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="login-email-live-feedback">
            <span v-if="formErrors.message === undefined">
              {{ trans('auth.login.messages.validEmailRequired') }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-password-group"
          :label="capitalize(transChoice('global.password', 1))"
          label-for="login-password"
        >
          <b-form-input
            id="login-password"
            v-model="$v.password.$model"
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            name="login-password"
            :state="validateState('password')"
            :placeholder="trans('auth.login.yourPassword')"
            aria-describedby="login-password-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="login-password-live-feedback">
            <span v-if="formErrors.message === undefined">
              {{ trans('auth.login.messages.passwordRequired') }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::FirebaseAlert-->
        <div
          role="alert"
          :class="{ show: formErrors.message !== undefined }"
          class="alert fade alert-danger"
        >
          <div class="alert-text">
            {{ formErrorMessages[formErrors.message] }}
          </div>
        </div>
        <!--end::FirebaseAlert-->

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <a
            id="vary_login_forgot"
            href="#"
            class="text-dark-60 text-hover-primary my-3 mr-2"
          >
            {{ trans('auth.login.forgotPassword') }}
          </a>
          <button
            ref="vary_login_submit_btn"
            class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
            :class="submitButtonAnimation ? 'spinner spinner-light spinner-right' : ''"
            :disabled="submitButtonAnimation"
          >
            {{ trans('auth.login.login') }}
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::signin-->
  </div>
</template>

<script>
  import { computed, ref, onBeforeMount } from '@vue/composition-api'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'
  import useFormValidation from '@src/use/formValidation'

  import useVuelidate from '@vuelidate/core'
  import { required, email, minLength } from '@vuelidate/validators'

  import { LOGIN, LOGOUT, SET_ERROR } from '@src/store/auth'

  const fb = require('@src/firebaseConfig')

  export default {
    name: 'Login',
    setup (props, { root }) {
      onBeforeMount(() => {
        // root.$store.commit(SET_ERROR, {}) // Remove all previous error-messages?
      })

      const submitted = ref(false)
      const submitButtonAnimation = ref(false)
      const formErrors = computed(() => root.$store.state.auth.errors) // computed vuex replaces mapstate
      const formEmail = ref('')
      const formPassword = ref('')

      const formErrorMessages = computed(() => ({
        'auth/user-disabled': trans('auth.login.messages.userDisabled'),
        'auth/user-not-found': trans('auth.login.messages.wrongUsernameOrPassword'),
        'auth/wrong-password': trans('auth.login.messages.wrongUsernameOrPassword'),
        'auth/too-many-requests': trans('auth.login.messages.tooManyRequests'),
        'auth/automatically-logged-out': trans('auth.login.messages.autologout'),
        'auth/operation-not-allowed': trans('auth.login.messages.providerDisabled'),
        'custom/missing-user-in-db': trans('auth.login.messages.missingUserInDB'),
        'misc/unknown-error': trans('auth.login.messages.unknownError')
      }))

      const firebaseErrors = () => formErrors.value.message === undefined // Custom validation rule

      const validationRules = {
        email: {
          required,
          email,
          firebaseErrors
        },
        password: {
          required,
          minLength: minLength(8),
          firebaseErrors
        }
      }

      const $v = useVuelidate(
        validationRules,
        {
          email: formEmail,
          password: formPassword
        }
      )

      const { validateState } = useFormValidation($v, submitted)

      const onSubmit = async () => {
        root.$store.commit(SET_ERROR, {}) // Remove all previous error-messages
        submitted.value = true
        submitButtonAnimation.value = true

        $v.value.$touch()
        if (Object.keys($v.value.$errors).length > 0) {
          submitButtonAnimation.value = false
          return // Found errors, will be shown
        }

        await root.$store.dispatch(LOGOUT)

        // Login with Firebase Auth Service
        const firebaseLoginResult = await fb.auth.signInWithEmailAndPassword(
          $v.value.email.$model,
          $v.value.password.$model
        )
          .then((user) => user)
          .catch((err) => {
            console.log('[FirebaseLoginResult ERROR:', err)
            root.$store.commit(SET_ERROR, { message: err.code })
            return err
          })

        // Fetch additional required userdata from Firestore DB (users collection)
        const validUserData = firebaseLoginResult.code === undefined
          ? await root.$store.dispatch(LOGIN, { user: firebaseLoginResult.user, mode: 'manually' }) // eslint-disable-line object-property-newline, max-len
            .then((res) => res).catch((err) => {
              console.log('ERROR from VUEX LOGIN:', err)
              return err
            })
          : firebaseLoginResult // Hand off the ".code" to the IF statement

        if (validUserData.code !== undefined) {
          await root.$store.dispatch(LOGOUT)
          root.$store.commit(SET_ERROR, { message: validUserData.code })
          submitButtonAnimation.value = false
          return
        }

        root.$router.push('/dashboard').catch((err) => console.log(err))
      }

      return {
        formEmail,
        formPassword,
        formErrors,
        formErrorMessages,
        validateState,
        onSubmit,
        $v,
        trans,
        transChoice,
        capitalize,
        submitButtonAnimation
      }
    }
  }
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
